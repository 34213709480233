<app-header></app-header>
<main role="main" class="main container">

  <div class="content-wrapper">
    <div class="">
      <ol class="breadcrumb" style="background-color: #fff;">
        <li class="breadcrumb-item"><span style="color: #007bff; cursor: pointer;" (click)="RedirectHome()">Home</span>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Pickup</li>
      </ol>
    </div>

    <div *ngIf="showEstimationError" class="">
      <div class="alert alert-danger" role="alert">
        <p class="mb-0">{{estimationErrorMsg}}</p>
      </div>
    </div>

    <div class="accordion scheduling-container">

      <!--Address start-->
      <div class="card pick-address">
        <div class="card-header" id="headingThree">
          <span class="mb-0">
            <strong> Pickup Address</strong>
            <button (click)="editPickupAddress()"
              style="border: 0rem; background:none; cursor: pointer; float:right;">Edit</button>

          </span>
        </div>
        <div [className]=" showPickupAddressPanel == true ? 'collapse show': 'collapse' ">
          <div class="card-body">
            <div class=" col-sm-6" data-toggle="buttons">
              <ul class="address-list">
                <li [hidden]="true" class="m-1 mb-2 mt-2" *ngFor="let item of addresses">
                  <input type="radio" class="btn radioStyle" name="address" value="item" (change)="selectAddress(item)">
                  {{ item.formatAddress }}
                </li>
                <li>
                  <div class="row" >
                    <div class="col-2" style="margin: 0px;padding: 0px;margin-left: 1rem;" >Address</div>
                    <div class="col-8 input-group input-group-sm mb-1" style="margin: 0px;padding: 0px;" >
                      <input ngx-google-places-autocomplete #places="ngx-places"  class="form-control" aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm" placeholder="Enter Pickup Address" (blur)="onBlur($event)"
                       [(ngModel)]="formateAddress" (onAddressChange)="onChange($event)" />
                    </div>
                  </div>


                </li>
                <!-- <li>
                  <button type="button" class="btn btn-sm mr-0"
                      style="border: 0rem; color: #007bff; cursor: pointer; width:7rem ;  " (click)="onChange(null)">test</button>
                </li> -->
              </ul>
              <div class="alert alert-danger mt-2" [hidden]="!showAddressError" role="alert">
                Please select pickup address
              </div>
              <div class="alert alert-danger mt-2" *ngIf="showAddressValidationError" role="alert">
                {{showAddressValidationErrorMsg}}
              </div>
              <div>

                <div class="contact-info-container mt-2 mb-2">
                  <div class="h8 " style="font-weight: 600; margin-bottom: 1rem ; "> Please provide contact information to receive notifications </div>
                  <div class="row mb-2 ">

                    <div class="col-sm-2" style="margin-right: .5rem;" >
                      <label>Email</label>
                    </div>
                    <div class="col-sm-6 pl-1 pr-4">

                      <div class="input-group input-group-sm mb-1">
                        <input type="text" id="inputEmail3" [(ngModel)]="this.scheduleAssert.contactInfo.EmailId" email
                         class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                      </div>

                    </div>
                  </div>
                  <div class="row" >
                    <div class="col-sm-2" style="margin-right: .5rem;">

                      <label>Mobile</label>
                    </div>
                    <div class="col-sm-6 pl-1 pr-4">

                      <div class="input-group input-group-sm mb-1">
                        <input type="text" id="inputMobile" [(ngModel)]="this.scheduleAssert.contactInfo.mobile"
                         class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                      </div>

                    </div>
                  </div>

                </div>

                <div class="alert alert-danger" [hidden]="!showContactError" role="alert">
                  {{ContactErrorMessage}}

                </div>
              </div>
            </div>
            <div class="col-sm-3 row pck-nxt-btn" *ngIf="formateAddress.length > 0" >
              <button class="btn mt-2 ml-4 btn-secondary show-detail-btn btn-sm collapsed" type="button"
                (click)="getOfferings(content)">Next</button>
            </div>
          </div>
        </div>
      </div>
      <!--Address end-->

      <div *ngIf="showOfferinganel" class="card pickup-type">
        <div class="card-header" id="headingOne">
          <span class="mb-0">
            <strong> Pickup Type</strong></span>
          <button (click)="editPickupOfferings()" [hidden]="!scheduleAssert.offeringtype"
            style="border: 0rem; background:none; cursor: pointer; float:right;">Edit</button>
        </div>

        <div id="collapseOne" [className]="showOfferinganel == true ? 'collapse show' : 'collapse'">
          <div class="card-body">
            <div class="row col-sm-12 ml-0 mr-0 ">
              <div class="col-md-2" *ngFor="let pickupOffer of pickupOfferings">
                <input type="radio" class="btn radioStyle" name="options" id="{{ pickupOffer}}"
                  (change)="selectPickupType(pickupOffer, $event)" [value]="pickupOffer" autocomplete="off">
                <span> {{pickupOffer}}</span>
                <span class="ml-1" ngbTooltip="{{pickupOffer}}">
                  <svg width=".8em" height=".8em" viewBox="0 0 16 16" class="bi bi-question-circle" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M5.25 6.033h1.32c0-.781.458-1.384 1.36-1.384.685 0 1.313.343 1.313 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.007.463h1.307v-.355c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.326 0-2.786.647-2.754 2.533zm1.562 5.516c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                  </svg>
                </span>
              </div>
            </div>
            <div class="row col-sm-12">
              <div class="col-md-6">
                <div class="alert alert-danger" [hidden]="!showPickupOfferTypeError" role="alert">
                  Please select pickup type
                </div>
              </div>
            </div>
            <div class="col-sm-3 pck-nxt-btn">
              <button class="btn mt-4 btn-secondary show-detail-btn btn-sm collapsed" type="button"
                (click)="chkshowPickupDatePanel(content)" data-toggle="collapse" aria-expanded="false">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showPickDate"  class="card pick-date-time">
        <div class="card-header" id="headingTwo">
          <span class="mb-0">
            <strong> Date and Time of pickup</strong></span>
          <!-- <button (click)="editPickupTime()" class="btn btn-sm mr-0 float-right"
            style="border: 0rem; cursor: pointer;">Edit</button> -->

        </div>
        <div id="collapseTwo" [className]="showPickupDatePanel ? 'collapse show' : 'collapse'">
          <div class="card-body">
            <div class="h6"> Select Date of pickup </div>
            <form class="form-inline date-pickr">
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" name="date-picker" placeholder="Select date" name="dp" [minDate]="minDate"
                    (dateSelect)="calenderDateSelection($event)" [(ngModel)]="scheduleDate" ngbDatepicker
                    #d="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary " (click)="d.toggle();" style="width: 40px;height: 38px;border: none;" type="button"><svg
                        class="bi bi-calendar3" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path fill-rule="evenodd"
                          d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg></button>
                  </div>
                </div>
              </div>
            </form>

            <div class="h6"> Select preferred date and time of pickup </div>
            <!-- <ngb-timepicker [meridian]="meridian" [(ngModel)]="time"></ngb-timepicker> -->

            <!-- <select class="form-control col-md-3" >
              <option *ngFor="let item of pickupTimeList" >{{item}}</option>
            </select>


            <div class="h6 mt-3"> Select preferred time buffer </div>
            <div class="row">

              <div class="col-md-2" *ngFor="let timeSlot of pickupTimeSlot">
                <label class="btn btn-md text-left btn-type btn-block active">
                  <input type="radio" name="timeSlot" (change)="selectPickupTimeSlot(timeSlot, $event)"
                    [value]="timeSlot" autocomplete="off"> {{timeSlot}}
                </label>
              </div>

            </div> -->


            <div class="row">
              <div class="col-sm m-0 p-0 m-1" *ngFor="let dateSlot of avilableSlots">
                <label class="btn btn-md text-center btn-type btn-block active dateSlot">
                  {{dateSlot.ScheduleDate}}
                </label>
                <div class="mb-2 " *ngFor="let times of dateSlot.timeSlots">
                  <button type="button" (click)="setScheduleSlot(times)"
                    [className]="times.isActive ? 'btn btn-full-width btn-outline-secondary active' : 'btn btn-full-width btn-outline-secondary'">
                    {{times.slotName}}
                  </button>
                </div>
              </div>
            </div>

            <div class="col-sm-3 mt-2 pck-nxt-btn">
              <button class="btn mt-2 btn-secondary show-detail-btn btn-sm collapsed" type="button"
                data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                (click)="getEstimation(content)">
                Next
              </button>
            </div>
          </div>
        </div>





      </div>


    </div>

    <div *ngIf="showEstimationError" class="">
      <div class="alert alert-danger" role="alert">
        <p class="mb-0">{{estimationErrorMsg}}</p>
      </div>
    </div>

  </div>
</main>
<app-footer></app-footer>

<ng-template #content let-c="close" let-d="dismiss">

  <div class="modal-body">
    loading..
  </div>

</ng-template>


<ng-template #myAddressmodal let-modal>
  <div class="modal-body">
    <div class="card">
      <div class="card-body new-address-card">
        <form>
          <div class="row mt-2 col-sm-*">
            <div class="col-sm-12 m-0 p-0 ">
              <div class="mb-3">
                <strong>Please confirm your address</strong>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row mt-2 col-sm-*">
              <div class="col-sm-12 m-0 p-0 ">
                <div class="input-group input-group-sm mb-3">
                  <input type="text" [(ngModel)]="newAddress.address1" id="addressLane1Id" name="address1" placeholder="Address" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                </div>
              </div>
            </div>
            <div class="row mt-2 col-sm-*">
              <div class="col-sm-4  m-0 p-0 pr-2 ">
                <div class="input-group input-group-sm mb-3">
                  <input type="text" class="form-control  address-text-input" name="city" id="cityId"
                  [(ngModel)]="newAddress.city" placeholder="City" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                </div>
              </div>
              <div class="col-sm-4  m-0 p-0 pr-2 ">
                <div class="input-group input-group-sm mb-3">
                  <select class="form-control" [(ngModel)]="this.newAddress.state" [ngModelOptions]="{standalone: true}"  (change)="changeAddState($event)">
                    <option *ngFor="let un_state of un_states" [ngValue]="un_state.abbreviation">
                      {{un_state.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4  m-0 p-0 ">
                <div class="input-group input-group-sm mb-3">
                  <input type="text" id="zipId" type="text" [(ngModel)]="newAddress.zipCode" name="zipId" placeholder="ZIP code"
                   class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                </div>

              </div>
            </div>
            <div class="row mt-1 col-sm-*" [hidden]="(newAddress.address1.length > 0)" >
              <div class="col-sm-10 alert alert-warning" role="alert">
               Address, City and Zip codes are mandatory
              </div>
            </div>
            <div class="row mt-1 col-sm-*">
              <div class="col-sm-3">
                <button [disabled]="!(newAddress.address1.length > 0)" class="btn btn-outline-primary addr-save-btn btn-sm " style="width:100px" type="button"
                  (click)="saveAddress(modal,content)" aria-expanded="false">
                  Save
                </button>
              </div>
              <div class="col-sm-3">
                <button type="button" class="btn cancel-adr-btn btn-secondary btn-sm " style="width:100px" (click)="modal.dismiss('Cross click')">Cancel</button>
              </div>
            </div>
            <div [hidden]="!showAddAddressMessage" class="row" class="alert alert-danger" role="alert">
              Error while create new address
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

