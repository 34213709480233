import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
  })
export class eqrInterceptorService implements HttpInterceptor {

    constructor(private authService: AuthenticationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       const token = this.authService.userContext;
       const tokenString = this.authService.token;
       let newHeaders = req.headers;
       newHeaders = newHeaders.append('token', tokenString);
       newHeaders = newHeaders.append('UserName', 'Consumer');

       if (token) {
          // If we have a token, we append it to our new headers
          newHeaders = newHeaders.append('customerId', token.contactid);
       }
       const authReq = req.clone({headers: newHeaders});
       return next.handle(authReq);
    }
 }
