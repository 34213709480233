<nav class="navbar  mb-2 navbar-dark" >



    <span  class="navbar-brand mb-0 h1">
        <a routerLink="/lander" ><img class="brand-logo-img ml-3 " style="width: auto; height: 50px;;"
            src="./../../../assets/img/EliteLogosvgWhite11.png"></a>
    </span>

    <!--       
    <span class="navbar-text" [hidden]="!isLoggedIn"> Support : +1XXXXXXX32 </span> -->
    <!-- <a></a><a></a> <a></a> <a></a> -->
    <span class="navbar-text"></span>
    <span class="navbar-text"></span> <span class="navbar-text"></span><span class="navbar-text"></span><span
        class="navbar-text"></span><span class="navbar-text"></span>
        <span class="navbar-text"></span><span class="navbar-text"></span>
        <span class="navbar-text"></span><span class="navbar-text"></span>
        <span class="navbar-text"></span><span class="navbar-text"></span>
        <span class="navbar-text"></span><span class="navbar-text"></span>
        <span class="navbar-text">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-envelope" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
              </svg>
            <a class="contact-link" >Message</a></span>
        <!-- <span class="navbar-text">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-chat-right-dots" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M2 1h12a1 1 0 0 1 1 1v11.586l-2-2A2 2 0 0 0 11.586 11H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
                <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            <a class="contact-link" >Chat</a>
        </span> -->
        <span class="navbar-text">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-headset" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 1a5 5 0 0 0-5 5v4.5H2V6a6 6 0 1 1 12 0v4.5h-1V6a5 5 0 0 0-5-5z"/>
                <path d="M11 8a1 1 0 0 1 1-1h2v4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8zM5 8a1 1 0 0 0-1-1H2v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8z"/>
                <path fill-rule="evenodd" d="M13.5 8.5a.5.5 0 0 1 .5.5v3a2.5 2.5 0 0 1-2.5 2.5H8a.5.5 0 0 1 0-1h3.5A1.5 1.5 0 0 0 13 12V9a.5.5 0 0 1 .5-.5z"/>
                <path d="M6.5 14a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1z"/>
              </svg>
            <a class="contact-link" >Contact us</a></span>
    <span [hidden]="!isLoggedIn" class="navbar-text" >
        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13 14s1 0 1-1-1-4-6-4-6 3-6 4 1 1 1 1h10zm-9.995-.944v-.002.002zM3.022 13h9.956a.274.274 0 0 0 .014-.002l.008-.002c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664a1.05 1.05 0 0 0 .022.004zm9.974.056v-.002.002zM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          </svg>
        {{LoggedInUser}} </span>
    <span [hidden]="!isLoggedIn"><button class="btn btn-signout  btn-sm pl-2 " type="button" (click)="logout()">Sign
            Out</button> </span>


</nav>