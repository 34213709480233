<!-- Masthead -->
  <header class="masthead text-white text-center">
    <!-- <button class="btn btn-danger btn-rounded" [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle" >Sign In</button> -->

    <button class="btn btn-danger btn-rounded" (click)="goHome()" >Sign In</button>

    <ng-template #popTitle>Sign in</ng-template>
    <ng-template #popContent>
      <app-singin></app-singin>
    </ng-template>

    <div class="overlay"></div>

    <div class="banner-head container">
      <div class="row text-center">
        <div class="col-lg-8">
          <img class="brand-logo-img" src="/assets/img/EliteLogosvgBlueandBlue.png">
        </div>
        <!-- <div class="col-lg-6">
          <app-singin></app-singin>
        </div> -->
      </div>
      <div class="row">
        <div class="banner-text col-md-10 mx-auto text-left">
          <h1 class="mb-3 ">SAY <span >GOODBYE</span> TO THE HASSLES AND HIGH COSTS OF <span class="red-text">EQUIPMENT RETURN</span></h1>
        </div>


      </div>
    </div>
  </header>
<!-- <section>
  <ng-template #popContent>
    <app-singin></app-singin>
  </ng-template>
<ng-template #popTitle>Sign in</ng-template>
<button type="button" class="btn btn-outline-secondary" [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle">
 Signin
</button>

</section> -->

  <!-- Icons Grid -->
  <section class="features-icons bg-light text-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div class="features-icons-icon d-flex">
              <!-- <img class="banner-icon m-auto text-primary" src="./../../assets/img/box.png"> -->
               <img class="banner-icon m-auto text-primary" src="/assets/img/Box_256_outline.png">

            </div>
            <h3>Box shipments</h3>
            <p class="lead mb-0">Pack it, ship it, done!  Boxes with return labels sent directly to you.</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div class="features-icons-icon d-flex">
              <img class="banner-icon m-auto text-primary" src="/assets/img/Door_256_mod.png">
              <!-- <img class="banner-icon m-auto text-primary" src="./../../assets/img/door.png"> -->
            </div>
            <h3>Pick up at your door-step</h3>
            <p class="lead mb-0">Schedule fast and convenient pick up and return of your equipment.</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="features-icons-item mx-auto mb-0 mb-lg-3">
            <div class="features-icons-icon d-flex">
              <!-- <img class="banner-icon-sat m-auto text-primary" src="./../../assets/img/sat.png"> -->
              <img class="banner-icon-sat m-auto text-primary" src="/assets/img/Dish_256_mod.png">
            </div>
            <h3>Dismantling service</h3>
            <p class="lead mb-0">Be safe and secure with our satellite dish and equipment dismantling and return service.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="showcase">
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-lg-6 order-lg-2 text-white showcase-img" >
          <!-- style="background-image: url('./../../assets/img/OverviewBG.jpg');"> -->

          <video class="landing-video" src="/assets/images/1.mp4" controls="controls" preload="none" onclick="this.play()" >

          </video>
        </div>
        <div class="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2 class="overview-sub-head">Overview</h2>
          <p class="lead mb-3">Elite Equipment Return is a technology and service company offering safe, secure and convenient nationwide pick up and return of assets between customers and businesses.</p>
          <h4>Customers</h4>
          <p class="lead mb-3">Save time and enjoy the convenience of having the equipment picked up at your door, or a location of your choosing.  Don’t want to pay for the unreturned equipment fee?  Have it picked up now or schedule a date and time that works for you.</p>
          <h4>Clients</h4>
          <p class="lead mb-3">Elite Equipment Return offers an innovative solution for asset and equipment recovery that was designed to offer an exceptional customer experience at a low cost to clients.  Our partnerships allow us to reach more of your customers throughout the United States putting more assets back in your possession and generating revenue.</p>
          <p class="lead mb-3">Elite Equipment Return was designed to take the stress out of the equipment recovery and return process, and offer a solution that is financially beneficial for both customers and clients.  Sign up now and start your Elite experience.</p>


        </div>
      </div>
    </div>
  </section>

  <!-- Call to Action -->
  <section class="call-to-action text-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8 mb-4">
          <h3 class="text-center">Contact Us</h3>
          <form name="sentMessage" id="contactForm" novalidate>
            <div class="control-group form-group">
              <div class="controls">
                <label>Full Name:</label>
                <input type="text" class="form-control" id="name" required
                  data-validation-required-message="Please enter your name.">
                <p class="help-block"></p>
              </div>
            </div>
            <div class="control-group form-group">
              <div class="controls">
                <label>Phone Number:</label>
                <input type="tel" class="form-control" id="phone" required
                  data-validation-required-message="Please enter your phone number.">
              </div>
            </div>
            <div class="control-group form-group">
              <div class="controls">
                <label>Email Address:</label>
                <input type="email" class="form-control" id="email" required
                  data-validation-required-message="Please enter your email address.">
              </div>
            </div>
            <div class="control-group form-group">
              <div class="controls">
                <label>Message:</label>
                <textarea rows="10" cols="100" class="form-control" id="message" required
                  data-validation-required-message="Please enter your message" maxlength="999"
                  style="resize:none"></textarea>
              </div>
            </div>
            <div id="success"></div>
            <!-- For success/fail messages -->
            <button type="submit" class="btn btn-danger btn-contact" id="sendMessageButton">Contact</button>
          </form>
        </div>

      </div>
    </div>
  </section>

  <!-- Footer -->
  <footer class="footer bg-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 h-100 text-center text-lg-left my-auto">
          <p class="text-muted small mb-4 mb-lg-0">

            <a routerLink="/terms" class="text-muted hov-highlight" >Terms and Services</a></p>
        </div>
        <div class="col-lg-6 h-100 text-center text-lg-left my-auto">
          <p class="text-muted small mb-4 mb-lg-0">&copy; <strong>Elite Equipment Return All Rights Reserved.</strong></p>
        </div>

      </div>
    </div>
  </footer>


