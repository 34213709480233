export class  assetModel
    {
    assetId: string;
    assetName: string;
    TypeOfasset: string;
    description: string;
}


    export class eqtAddress
    {
        addressId: string;
        formateAddress : string;
        address1: string;
        address2 : string;
        city: string;
        zipCode: string;
        state: string;
        customeraddressid: string;
}

export class paymentConformationRequest
{
    eroid: string;
    erguid: string;
    paymentmethod: string;
    amount: string;
    tokennumber: string;
}

    export class assetScheduleModel
    {
        customerId: string;
        assetScheduleId : string;
        scheduleDate  : string;
        betweenTime : string;
        assets  : assetModel[];
        status  : string;
        ERONumber : string;
        ScheduleAddress: eqtAddress;
        DropAddress: eqtAddress;
        contactInfo: ContactInfo;
        pickupType: string;
        EstimationPrice: string;
        contactOption: string;
        vendorid: string;
        offeringtype: string;
    }



    
    export class ContactInfo
    {
        contactInfoId: string;
        EmailId: string;
        mobile: string;
    }

    export class NotesRequestModel
    {
        customerId: string;
        description: string;
        subject: string;
    }