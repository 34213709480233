import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-singin',
  templateUrl: './singin.component.html',
  styleUrls: ['./singin.component.css']
})

export class SinginComponent implements OnInit {

  maskedPhone = '';
  maskedEmail = '';
  codeSource = '1';
  authCode = '';
  timeLeft = 0;
  flowObject = this.getValidationObject();

  customerDetails:any;
  authText = '';
  constructor(public authService: AuthenticationService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.flowObject.stage = 1;
    this.authService.getIdentity().subscribe((data) => {
      this.authService.token = data.authToken;
    });;
  }

  getValidationObject() {
    return {
      stage:1,
      showError:0,

    }
  }

  Authenticated() {
    this.flowObject.showError = 0;
    this.authService.verification(this.authText).subscribe((data) => {
      if(data) {
        let responseObject = JSON.parse(data);
        this.customerDetails = responseObject[0];

        this.maskedPhone = this.customerDetails.mobilephone ? this.customerDetails.mobilephone: '';
        this.maskedEmail = this.customerDetails.emailaddress1 ? this.customerDetails.emailaddress1 : '';
      }
      this.flowObject.stage = 2;

      console.log(this.customerDetails);
    },err => {
      this.flowObject.showError = 1;
    });

  }

  sendVerification() {

    this.flowObject.showError = 0;
    // if(this.codeSource === '1') {
    //   this.authService.sendVerification(this.customerDetails.mobilephone , '',this.authText).subscribe((data) => {
    //     this.flowObject.stage = 3;

    //   }, err => {

    //   });
    // }
  }
  clearAccount() {
    this.flowObject.stage = 1;
    this.flowObject.showError = 0;
  }
  SubmitAuthenticated() {
    this.authService.isloggedIn = true;
    this.authService.userContext = this.customerDetails;
    this.router.navigate(['/equipment'], { state: this.customerDetails });
    // this.authService.verificationCode(this.selectedPhone, this.selectedEmail, this.authCode, referenceId).subscribe((data) => {
    //   const responseData = JSON.parse(data);
    //   if (data) {
    //     if (responseData.validate) {
    //       this.authService.isloggedIn = true;
    //       // Redirected to equipment
    //       this.router.navigate(['/equipment'], { state: this.customerDetails });
    //       // this.router.navigate(['/equipment', { id: 'asdfasdfasdfasdf' }]);
    //     } else {
    //       this.showverificationInputError = true;
    //     }
    //   }
    // }, err => {
    //   this.showverificationInputError = true;
    // });
  }


  selectSource($event) {
    console.log($event)

    if ($event.currentTarget.id == 'rdphone') {
      this.codeSource = '1';
    } else if ($event.currentTarget.id == 'rdemail') {
      this.codeSource = '2';
    }
  }


}
