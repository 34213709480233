import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { assetService } from '../services/assert.service';
import { paymentConformationRequest } from '../model/assertModel';
@Component({
  selector: 'app-ero-crm-order',
  templateUrl: './ero-crm-order.component.html',
  styleUrls: ['./ero-crm-order.component.css']
})
export class EroCrmOrderComponent implements OnInit {
  eroGuid = ''
  eroId = ''
  bankAuthCode = ''
  paymentTransactionId = ''
  _customerid_value = ''
  pickupdata
  userContext
  isSubmittedSuccess = false;
  isSubmittedFail = false;
  constructor(public authService: AuthenticationService, 
    private service: assetService, private router: Router, private route: ActivatedRoute) { 
    
  }

  ngOnInit(): void {
    this.authService.getIdentity().subscribe((data) => { 
      this.authService.token = data.authToken;
      this.pageload();
  });
   
  }

  pageload() {
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      const fullId = params['hpp_item_description_1'];
      const arr = fullId.split('|');
      // Defaults to 0 if no query param provided.
      this.eroId = arr[0] || ''; // params['hpp_item_description_1'] || '';
      this.paymentTransactionId = params['bank_card_transaction_id'] || '';
      const eroGUID = params['hpp_item_description_2'] || '';
      const paidAmount = params['hpp_amount'] || '';
      this.bankAuthCode = params['bank_card_transaction_auth_code'] || '';
      const paymentObject = new paymentConformationRequest();

      this.eroGuid = params['hpp_item_description_2'] || '';

      paymentObject.amount = paidAmount;
      paymentObject.eroid = this.eroId; // eroGUID;
      paymentObject.erguid = this.eroGuid;
      paymentObject.tokennumber = this.bankAuthCode;


     
      if(this.bankAuthCode !== '' ) {
        this.isSubmittedSuccess = true;
        this.isSubmittedFail = false;
      } else {
        this.isSubmittedSuccess = false;
        this.isSubmittedFail = true;
      }

      this.service.paymentConformation(paymentObject).subscribe((paymentResponse) => {
        
      });
    });
  }

  closeWindow() {
    window.close();
  }
  
  
  RedirectHome() {
    this.router.navigate(['/equipment']);
  }

}
