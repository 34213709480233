 
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { PlatformLocation } from '@angular/common';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
 
    constructor(private router: Router, location: PlatformLocation, private authService: AuthenticationService ) {
        /*location.onPopState((event) => {
            console.log('back/forward btn pressed');
            if (!this.authService.isloggedIn) {
                setTimeout(() => this.router.navigate(['/home']), 50);
            } else {
                 setTimeout(() => this.router.navigate(['/equipment']), 50);
            }
        });*/
    }
 
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean|UrlTree {
        
        if(route.routeConfig.path === 'order' ) {
            if(route.queryParams) {
                if(route.queryParams.bank_card_transaction_id) {
                    if(route.queryParams.hpp_item_description_1) {
                        return true;
                    }
                }
            }
        } else if (!this.authService.isloggedIn) {
            
            this.router.navigate(['/home']);
            return false;
        } 
 
        return true;
    }
 
}
 