import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import * as data from '../../assets/content.json';

@Component({
  selector: 'app-lander',
  templateUrl: './lander.component.html',
  styleUrls: ['./lander.component.css']
})
export class LanderComponent implements OnInit {

  options = {
    types: [],
    componentRestrictions: { country: 'UA' }
    }
  constructor(private route: ActivatedRoute,
    private router: Router) { }
  ngOnInit(): void {
    console.log(data);
    console.log((data as any).default);
    console.log((data as any).default.tooltips);
  }

  goHome() {
    this.router.navigate(['/home']);
  }
}
