import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {assetModel, assetScheduleModel} from './../model/assertModel'
import { assetService } from '../services/assert.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipment-view',
  templateUrl: './equipment-view.component.html',
  styleUrls: ['./equipment-view.component.css'],
  providers: [ NgbModalConfig, NgbModal]
})
export class EquipmentViewComponent implements OnInit {

  asserts = []
  selectedEquments: any[] = []
  scheduleAsserts = []
  state$: Observable<object>;
  LoggedInUser = ''
  customerDetails;
  customerTotalData

  @ViewChild('content')
  private contentTpl: TemplateRef<any>;

  constructor(public authService: AuthenticationService,
    private service: assetService, private route:ActivatedRoute,
    private router:Router, modelConfig: NgbModalConfig, private modalService: NgbModal) {

    this.LoggedInUser = this.authService.userContext.emailaddress1
    this.customerDetails = this.authService.userContext
  }

 logout() {
  this.authService.isloggedIn = false;
  this.authService.userContext = undefined;
  this.LoggedInUser = '';
   this.router.navigate(['/home']);
  }
  ngOnInit(): void {
    this.getAsserts();
  }
  getAsserts() {
    const cutomerId = this.customerDetails.contactid;

    this.modalService.open(this.contentTpl);
    this.service.getOpenEquipmentsByCustomerID(cutomerId).subscribe( (data) => {
      console.log(data.OpenEquipments);
      this.asserts = data.OpenEquipments;
      this.modalService.dismissAll();
    },
    (error) => {
      this.modalService.dismissAll();
    }
    );

    this.service.getEROsByCustomerId(cutomerId).subscribe( (data) => {
      console.log(data);
      if(data) {
        if(data.EquipmentOrders && data.EquipmentOrders.length > 0) {
          data.EquipmentOrders.forEach(element => {
            element['showDetails'] = false;
          });
        }
      }
      this.scheduleAsserts = data.EquipmentOrders;
    });

  }

  getScheduleList() {
     const cutomerId = this.customerDetails.contactid;
    this.service.getDetatils(cutomerId).subscribe((data) => {

    });
  }
  showPickupError = false
  selectEqument(assert, $event) {

   this.showPickupError = false;
    if( $event.target.checked) {
      let isExisted = false;
      for (let index = 0; index < this.selectedEquments.length; index++) {
        const element = this.selectedEquments[index];
        const tempId = element.EquipmentId;
        if(tempId === assert.EquipmentId) {
          isExisted = true;
        }
      }
      if(!isExisted) {
      this.selectedEquments.push(assert);
      }
    } else {
      let deleteIndex = -1;
      for (let index = 0; index < this.selectedEquments.length; index++) {
        const element = this.selectedEquments[index];
        const tempId = element.EquipmentId;
        if(tempId === assert.EquipmentId) {
          deleteIndex = index;
        }
      }
      if(deleteIndex > -1) {
        this.selectedEquments.splice(deleteIndex, 1);
      }
    }

  }
  showEROPanel = '';
  cancelEro(id) {
    this.modalService.open(this.contentTpl);
    this.service.cancelERO(id,'','').subscribe( (data) => {
      this.modalService.dismissAll();
      this.getAsserts();
    });
  }
  showERODetails(eroObject,id) {
    if(eroObject.showDetails ) {
      eroObject.showDetails = false;
    }
    else {
      eroObject.showDetails = true;
    }
  }
  schedulePick() {
    if (this.selectedEquments.length > 0) {
      const redirectData = {
        selectedEquments: this.selectedEquments,
        details: this.customerTotalData
      }
      this.router.navigate(['/pickup'], { state: redirectData} );
    } else {
      this.showPickupError = true;
    }
  }
}
