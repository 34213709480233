import { Injectable } from '@angular/core';
import { Observable, observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoddingService {

  constructor() { }


  private profileObs$: BehaviorSubject<any> = new BehaviorSubject(null);

    setProfileObs(content) {
        this.profileObs$.next(content);
    }


  callModel() {

  }

  showloadder(): Observable<any> {
    return this.profileObs$.asObservable();
  }
}
