<main role="main" class="main container">
  <div class="content-wrapper">
    <div class="accordion scheduling-container" id="reviewNPayContainer">
      <div class="card payment-card">
        <div class="card-header" id="reviewHeader">
          <span class="mb-0">
            <strong> Payment Conformation</strong></span>
        </div>
        <div id="reviewContainer" class="collapse show" aria-labelledby="reviewHeader"
          data-parent="#reviewNPayContainer">
          <div class="card-body" id="reviewBody">
            <div class="row">
              <div class="col-md-4">
                <strong>ERO Number:</strong>
              </div>
              <div class="col-md-6 mb-3">
                {{eroId}}
              </div>
            </div>
            <div class="row ">
              <div class="col-md-4">
                <strong>Authentication Code:</strong>
              </div>
              <div class="col-md-6 mb-3">
                {{bankAuthCode}}
              </div>
            </div>
            <div class="row ">
                <div class="col-md-12 mt-2 mb-3" style="text-align: center;" >
                  <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="closeWindow()" >Close</button> -->

                  <input type="button"  class="btn btn-sm btn-outline-secondary" id="close" onclick="window.close()" value="Close" />
                </div>
              </div>
          </div>

        </div>
      </div>


      <div class="card payment-card" [hidden]="!isSubmittedSuccess" >
       
          <div class="card-body">
            <div class="alert alert-success">
              <h6 class="alert-heading"> Your request created succesfully! </h6>
            </div>
          </div>
       
      </div>
      <div class="card payment-card" [hidden]="!isSubmittedFail" >
       
        <div class="card-body">
          <div class="alert alert-danger">
            <h6 class="alert-heading"> Your request failed! </h6>
            <hr>
            <p>Please contact customer care for assistence</p>

          </div>
        </div>
     
    </div>
    </div>
  </div>
</main>
