import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedIn = false
  LoggedInUser = ''
  customerDetails;
  constructor(public authService: AuthenticationService, private route:ActivatedRoute, private router:Router) {
   if(authService.userContext) {
    this.isLoggedIn = true
    this.LoggedInUser = this.authService.userContext.fullname
   } else {
    this.isLoggedIn = false
   }
  }

 logout() {
  this.authService.isloggedIn = false;
  this.authService.userContext = undefined;
  this.LoggedInUser = '';
   this.router.navigate(['']);
  }

  ngOnInit(): void {
  }

}
