import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoddingService } from './services/lodding.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class AppComponent {
  title = 'Equipment Returns';

  constructor(private router: Router, modelConfig: NgbModalConfig, private modalService: NgbModal,public lodService: LoddingService) {
    this.lodService.showloadder().subscribe( (response) => {
     // alert(response);
     if(response) {
      this.modalService.open(response);
     }
    })
  }
}

