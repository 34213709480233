import { Directive, OnInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appFindScroll]'
})
export class FindScrollDirective implements OnInit {

  constructor() { 
    console.log('lets track ths scroll');
  }
  ngOnInit(): void {
    console.log('lets track ths scroll');
  }

  @HostListener('scroll', ['$event'])
  public onScroll($event: Event): void {
     console.log($event);
  } 


}
