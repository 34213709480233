import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EquipmentViewComponent} from './equipment-view/equipment-view.component';
import {HomeComponent} from './home/home.component';
import {SchedulePickupComponent} from './schedule-pickup/schedule-pickup.component';
import {PaymentSuccessComponent} from './payment-success/payment-success.component';
import { ReviewComponent } from "./review/review.component";
import { AuthGuardService } from './services/auth-guard.service';
import { LanderComponent  } from "./lander/lander.component";
import { TermsComponent } from "./terms/terms.component";
import { EroOrderComponent } from './ero-order/ero-order.component';
import { EroCrmOrderComponent } from './ero-crm-order/ero-crm-order.component';
import { SearchComponent } from './search/search.component';


const routes: Routes = [
  { path: 'lander', component: LanderComponent},
  { path: 'home', component: HomeComponent, pathMatch: 'prefix'},
  { path: 'terms', component: TermsComponent},
  { path: 'equipment', component: EquipmentViewComponent,canActivate : [AuthGuardService] },
  { path: 'pickup', component: SchedulePickupComponent,canActivate : [AuthGuardService]},
  { path: 'review', component: ReviewComponent,canActivate : [AuthGuardService]},
  {path: '', redirectTo: '/lander', pathMatch: 'full'},
  {path: 'paymentSuccess', component:PaymentSuccessComponent, pathMatch: 'full'},
  { path:'order', component: EroOrderComponent, canActivate : [AuthGuardService] },
  { path:'crmorder', component: EroCrmOrderComponent, pathMatch: 'prefix' },
  { path: 'search' , component: SearchComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
