<app-header></app-header>
<main role="main" class="main container">
  <div class="content-wrapper">

    <div class="">
      <ol class="breadcrumb" style="background-color: #fff;">
        <li class="breadcrumb-item"><span style="color: #007bff; cursor: pointer;" (click)="RedirectHome()">Home</span>
        </li>        
        <li class="breadcrumb-item active" aria-current="page">Payment Conformation</li>
      </ol>
    </div>

    <div class="accordion scheduling-container" id="reviewNPayContainer">
      <div class="card payment-card">
        <div class="card-header" id="reviewHeader">
          <span class="mb-0">
            <strong> Review</strong></span>
        <!--  <a class=" edit-link " type="button" data-toggle="collapse" data-target="#reviewContainer"
            aria-expanded="true" aria-controls="reviewContainer">
            Edit
          </a>
-->
        </div>
        <div id="reviewContainer" class="collapse show" aria-labelledby="reviewHeader"
          data-parent="#reviewNPayContainer">
          <div class="card-body" id="reviewBody">
            <div class="row">
              <div class="col-md-4">
                <strong>ERO Number:</strong>
              </div>
              <div class="col-md-6 mb-3">
                {{eroId}}
              </div>
            </div>
            <div class="row ">
              <div class="col-md-4">
                <strong>Authentication Code:</strong>
              </div>
              <div class="col-md-6 mb-3">
                {{bankAuthCode}}
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="card payment-card" [hidden]="!isSubmittedSuccess" >
       
          <div class="card-body">
            <div class="alert alert-success">
              <h4 class="alert-heading"> Your request created succesfully! </h4>
              <hr>
              <p>Your ERO number is <Strong>{{eroId}}</Strong></p>

            </div>
          </div>
       
      </div>
      <div class="card payment-card" [hidden]="!isSubmittedFail" >
       
        <div class="card-body">
          <div class="alert alert-danger">
            <h4 class="alert-heading"> Your request failed! </h4>
            <hr>
            <p>Please contact customer care for assistence</p>

          </div>
        </div>
     
    </div>
    </div>
  </div>
</main>
<app-footer></app-footer>

<ng-template #content let-c="close" let-d="dismiss">

  <div class="modal-body">
    loading..
  </div>

</ng-template>