<div class="row col" style="width: 100%;">

  <div *ngIf="flowObject.stage === 2 || flowObject.stage === 3" class="input-group mb-3 h6">
    <a style="cursor: pointer;" (click)="clearAccount()">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x-circle" fill="red"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path fill-rule="evenodd"
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg> </a>
    <span class="ml-1">{{authText}}</span>
  </div>


  <div class="row" *ngIf="flowObject.stage === 1">

    <form class="mt-2 col-12" style="width: 100%; min-width: 320px">
      <div class="form-group">
        <input type="text" class="form-control resp-text  form-control-sm pl-1" id="userInputKey" name="authText"
          style="border: 1px solid gray; border-radius: 4px; padding:1px ; " [(ngModel)]="authText"
          aria-describedby="inputMobile" placeholder="Mobile Number or Account Number or Email" required>
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="flowObject.showError === 1">
        We can not validate your account. Please contact our customer care center.
      </div>
      <button type="button" class="btn btn-outline-dark btn-sm" (click)="Authenticated()">Sign In</button>
    </form>
  </div>

  <div class="row" *ngIf="flowObject.stage === 2">

    <form class="mt-2 col-12">
      <div>
        <div class="form-group  alert-primary text-left p-1">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle-fill pr-1 " fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
          <span class="mb-3 col-12 alert-primary rounded-corner" style="padding-left: 0;">To Verify your Identity, we
            need to send you a verification code. <br /> Please select your preferred contact method. <br />Data charges
            from your provider may apply.</span>
        </div>
        <div *ngIf="flowObject.showError === 1" class="form-group  alert-danger text-left p-1">
          <span class="mb-3 col-12 alert-danger rounded-corner" style="padding-left: 0;"> We can not validate your
            account. Please contact our customer care center.</span>
        </div>
      </div>

    </form>
    <form class="mt-2">
      <div class="form-group">

        <div class="row">


          <div *ngIf="customerDetails.mobilephone.length > 0" class=" col-12 ml-3 mb-1 custom-control custom-radio">
            <input type="radio" id="rdphone" name="sendVerification" class="custom-control-input"
              (change)="selectSource($event)">
            <label class="custom-control-label" for="rdphone">{{maskedPhone}}</label>
          </div>


          <div  *ngIf="customerDetails.emailaddress1.length > 0" class="col-12 ml-3  custom-control custom-radio">
            <input type="radio" id="rdemail" name="sendVerification" class="custom-control-input"
              (change)="selectSource($event)">
            <label class="custom-control-label" for="rdemail">{{maskedEmail}}</label>
          </div>

          <div class="col-12 ml-3  custom-control custom-radio">
            <button type="button" class="btn btn-outline-dark btn-sm" (click)="sendVerification()">Send Code</button>
          </div>
        </div>

      </div>
    </form>
  </div>


  <div class="row" *ngIf="flowObject.stage === 3">

    <form class="mt-2 col-12">
        <div class="form-group">
            <label for="userInputKey" id="inputMobile" hidden>Enter Authentication Code</label>
            <div class="row">

            </div>

            <div class="input-group input-group-sm mb-3"
                style="border: 1px solid gray; border-radius: 4px; padding:1px ; ">
                <input type="text" style="border: 0;" class="form-control" [(ngModel)]="authCode"  name="authCode"
                    placeholder="Verification code" aria-label="Recipient's username"
                    aria-describedby="basic-addon2">
                <!-- <div class="input-group-append">
                    <button [hidden]="timeLeft > 0"  class="btn get-code-btn" style="width: 100px;height: 25px;"
                      (click)="sendVerification()" type="button">Get Code</button>
                    <span class="btn get-code-btn" style="width: 100px;height: 25px;"  >00:{{timeLeft}}</span>
                </div> -->
            </div>

        </div>

        <div *ngIf="flowObject.showError === 1" class="alert alert-danger" role="alert">
            not a valid code
        </div>

        <button type="button" class="btn btn-outline-dark btn-sm" (click)="SubmitAuthenticated()">Sign in</button>
    </form>
</div>




</div>
