import { Component, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { assetService } from '../services/assert.service';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

import { DomSanitizer } from "@angular/platform-browser";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css'],
  providers: [NgbTimepickerConfig, DatePipe,NgbModalConfig, NgbModal]
})
export class ReviewComponent implements OnInit {
  @ViewChild('content')
  private contentTpl: TemplateRef<any>;
  pickupdata
  customerTotalData
  content = ''
  newERONumber = ''
  userContext
  showPaymentPanel = false;
  paymentURL;
  state$: Observable<object>
  constructor(public authService: AuthenticationService,private datePipe: DatePipe, private sanitizer: DomSanitizer,
    config: NgbTimepickerConfig, private route: ActivatedRoute, private router: Router, private service: assetService,
    modelConfig: NgbModalConfig, private modalService: NgbModal) {

    this.userContext = this.authService.userContext
  }

  ngOnInit(): void {

    this.state$ = this.route.paramMap
    .pipe(map(() => window.history.state))

    this.state$.subscribe((data: any) => {
      this.pickupdata = data.pickupdata
      this.customerTotalData = data.details;
      if(data.timeSlot) {
        this.timeSlot = data.timeSlot;
      }
    });
  }
  timeSlot = ''

  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
    //console.log(window.pageYOffset, event);
 }

 onMouseWheel(event) {
 // console.log('Wheel event: ', event.deltaY);
 }

  RedirectSchedule() {
    const redirectData = {
      selectedEquments: this.pickupdata.assets,
      details: this.customerTotalData,
      address: this.pickupdata.ScheduleAddress
    }

    this.router.navigate(['/pickup'], { state: redirectData} );
  }

  RedirectHome() {
    this.router.navigate(['/equipment'], { state: this.userContext });
  }
  ClosePayment() {
    this.router.navigate(['/equipment'], { state: this.userContext });
    this.modalService.dismissAll();
  }
  acceptReviewConditions($event) {


    let status = 0;
    if($event.currentTarget.checked) {
      this.showPaymentPanel = true;
      status = 1;
    } else {
      this.showPaymentPanel = false;
      status = 0;
    }

    this.service.acceptPayment(status).subscribe( (response) => {

    },(error) =>{

    });

  }

  onDisclamierScroll($event) {
   // console.log($event);
  }

  submitERO(paymentContent:any) {
    this.isSubmitted = true;
    //this.modalService.open(paymentContent,{ size:'xl'});
    this.modalService.open(this.contentTpl);
    let assertsArray = [];

    if(this.pickupdata.assets) {
      for (let index = 0; index < this.pickupdata.assets.length; index++) {
        const element = this.pickupdata.assets[index];
        assertsArray.push(element['EquipmentId']);

      }
    }

    let orderAmount = this.pickupdata.EstimationPrice ? this.pickupdata.EstimationPrice: '0';

    let postEroData = {
      scheduleDate: this.pickupdata.scheduleDate,
      additionalInstructions: '',
      mobile: this.pickupdata.contactInfo.mobile,
      EmailId: this.pickupdata.contactInfo.EmailId,
      assets: assertsArray.join(','),
      ScheduleAddressId:0,
      offeringtype: this.pickupdata.offeringtype,
      EstimationPrice: orderAmount,
      CustomerId:this.pickupdata.customerId,
      ScheduleAddress: this.pickupdata.ScheduleAddress
    }






     this.service.createERO(postEroData).subscribe( (data) => {
      this.modalService.dismissAll();
      this.modalService.open(paymentContent,{ size:'xl'});
       let url = data.paymentUrl;
       this.paymentURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);

       //this.modalService.dismissAll();


     },(error) => {
       //console.log(error);
       this.isSubmittedFail = true;
       this.errorMessage = "Unable to process for payment. Please contact Administrator";
       this.modalService.dismissAll();
     });
   }

   errorMessage = '';
  isSubmitted = false;
  isSubmittedSuccess = false;
  isSubmittedFail = false;
}
