import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { assetService } from '../services/assert.service';
import { paymentConformationRequest } from '../model/assertModel';
@Component({
  selector: 'app-ero-order',
  templateUrl: './ero-order.component.html',
  styleUrls: ['./ero-order.component.css']
})
export class EroOrderComponent implements OnInit {

  eroId = ''
  eroGuid = ''
  bankAuthCode = ''
  paymentTransactionId = ''
  _customerid_value = ''
  pickupdata
  userContext
  isSubmittedSuccess = false;
  isSubmittedFail = false;
  constructor(public authService: AuthenticationService, 
    private service: assetService, private router: Router, private route: ActivatedRoute) { 
    
  }
  

  ngOnInit(): void {
    this.authService.getIdentity().subscribe((data) => { 
      this.authService.token = data.authToken;
      this.pageload();
  });
  }
  
  pageload() {
    this.route
    .queryParams
    .subscribe(params => {

      const fullId = params['hpp_item_description_1'];
      //const arr = fullId.split('|');
      // Defaults to 0 if no query param provided.
      this.eroId = fullId; // arr[0] || ''; // params['hpp_item_description_1'] || '';
      this.paymentTransactionId = params['bank_card_transaction_id'] || '';
      const paidAmount = params['hpp_amount'] || '';
      this.bankAuthCode = params['bank_card_transaction_auth_code'] || '';


      this.eroGuid = params['hpp_item_description_2'] || '';


      const paymentObject = new paymentConformationRequest();

      paymentObject.amount = paidAmount;
      paymentObject.eroid = this.eroId; // eroGUID;
      paymentObject.erguid = this.eroGuid;
      paymentObject.tokennumber = this.bankAuthCode;
      if(this.eroId !== '') {
      this.service.paymentConformation(paymentObject).subscribe((paymentResponse) => {
        
      });
    }
      if(this.bankAuthCode !== '' ) {
        this.isSubmittedSuccess = true;
        this.isSubmittedFail = false;
      } else {
        this.isSubmittedSuccess = false;
        this.isSubmittedFail = true;
      }
      console.log(this.eroId);
      this.authService.verification(this.eroId).subscribe((userResoponse) => {
        console.log(userResoponse);
        const customerDetails = JSON.parse(userResoponse);
        this.authService.userContext = customerDetails[0];
        this.authService.customerId = userResoponse.contactid;
        this.authService.isloggedIn = true;
      });
    });
  }
  
  RedirectHome() {
    this.router.navigate(['/equipment']);
  }

}
