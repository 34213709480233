import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { EmailValidator, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EquipmentViewComponent } from './equipment-view/equipment-view.component';
import { HomeComponent } from './home/home.component';
import { SchedulePickupComponent } from './schedule-pickup/schedule-pickup.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { LanderComponent } from './lander/lander.component';
import { ReviewComponent } from './review/review.component';
import { EroHomeItemComponent } from './ero-home-item/ero-home-item.component';
import { TermsComponent } from './terms/terms.component';
import { eqrInterceptorService } from './services/eqrInterceptorService';
import { EroOrderComponent } from './ero-order/ero-order.component';
import { EroCrmOrderComponent } from './ero-crm-order/ero-crm-order.component';

import { SwcScrollHeaderDitective } from './review/swc-scroll-header.directive';
import { FindScrollDirective } from './find-scroll.directive';
import { SinginComponent } from './singin/singin.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SearchComponent } from './search/search.component';

@NgModule({
  declarations: [
    AppComponent,
    EquipmentViewComponent,
    HomeComponent,
    SchedulePickupComponent,
    PaymentSuccessComponent,
    HeaderComponent,
    FooterComponent,
    LanderComponent,
    ReviewComponent,
    EroHomeItemComponent,
    TermsComponent,
    EroOrderComponent,
    EroCrmOrderComponent,
    SwcScrollHeaderDitective,
    FindScrollDirective,
    SinginComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    GooglePlaceModule
  ],
  exports: [
    LanderComponent,
    SchedulePickupComponent
  ],
  providers: [ EmailValidator, {provide: HTTP_INTERCEPTORS, useClass: eqrInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
