import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  showMobileRadio = true;
  showEmailRadio = true;

  showInformation = true;
  showNoContact = false;

  authText = ''
  authCode = ''
  showUserInputError = false;
  showsendInputError = false;
  showverificationInputError = false;
  public showAuthCode = false;
  customerDetails;
  maskedPhone = ''
  maskedEmail = ''
  selectedPhone = ''
  selectedEmail = ''
  showVerificationPanel = false
  showSendPanel = false

  hideSignInButton = false
  hideSendCodeButton = true
  hideVerificationButton = true

  verification_code_disabled = true;

  constructor(public authService: AuthenticationService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.authService.getIdentity().subscribe((data) => {
      this.authService.token = data.authToken;
    })
  }
  timeLeft: number = 0;
  interval;
  subscribeTimer: any;


  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft === 0) {
          clearInterval(this.interval);
        }
      }
    }, 1000);
  }


  selectSource($event) {
    console.log($event)

    if ($event.currentTarget.id == 'rdphone') {
      this.selectedPhone = this.customerDetails[0].mobilephone
      this.selectedEmail = ''
      this.showVerificationPanel = true
    } else if ($event.currentTarget.id == 'rdemail') {
      this.selectedPhone = ''
      this.selectedEmail = this.customerDetails[0].emailaddress1
      this.showVerificationPanel = true
    }
  }
  getMaskedPhone(source) {
    let returnValue = ''
    if (source.length > 0) {
      const firstpart = source.substring(0, 2)
      const lastPart = source.substring(source.length - 2)
      const middleText = 'X-XXX-XX'
      returnValue = firstpart + middleText + lastPart
    }
    return returnValue
  }
  getMaskedEmail(source) {
    let returnValue = ''
    if (source.length > 0) {
      const emailArr = source.split('@')
      const firstpart = emailArr[0].substring(0, 2)
      const lastPart = source.substring(emailArr[0].length)
      const middleText = 'XXXXXXXXXX'
      returnValue = firstpart + middleText + lastPart
    }
    return returnValue
  }
  verificationSentObj
  sendVerification() {

    this.authService.sendVerification(this.selectedPhone, this.selectedEmail,this.authText).subscribe((data) => {
      this.verification_code_disabled = false;
      this.verificationSentObj = JSON.parse(data) //refId
      this.showVerificationPanel = true
      this.hideSignInButton = true
      this.hideSendCodeButton = false
      this.hideVerificationButton = false
    }, err => {
      this.verification_code_disabled = true;
      this.showsendInputError = true;
    });
    this.timeLeft = 45;
    this.startTimer();
  }
  Authenticated() {

    this.authService.verification(this.authText).subscribe((data) => {
      this.customerDetails = JSON.parse(data);
      this.showUserInputError = false;
      this.showAuthCode = true;
      this.authService.userContext = this.customerDetails[0]
      this.authService.customerId = data.contactid;

      if (!this.customerDetails[0].mobilephone && !this.customerDetails[0].emailaddress1) {
        this.showInformation = false;
        this.showNoContact = true;
      } else if (this.customerDetails[0].mobilephone == '' && this.customerDetails[0].emailaddress1 == '') {
        this.showInformation = false;
        this.showNoContact = true;
      }






      if (this.customerDetails[0].mobilephone) {
        this.maskedPhone = this.getMaskedPhone(this.customerDetails[0].mobilephone)
      }
      if (this.customerDetails[0].emailaddress1) {
        this.maskedEmail = this.getMaskedEmail(this.customerDetails[0].emailaddress1)
      }

      if (this.customerDetails[0].mobilephone) {
        if (this.customerDetails[0].mobilephone === '') {
          this.showMobileRadio = false;
        } else {
          this.showMobileRadio = true;
        }
      } else {
        this.showMobileRadio = false;
      }

      if (this.customerDetails[0].emailaddress1) {
        if (this.customerDetails[0].emailaddress1 === '') {
          this.showEmailRadio = false;
        } else {
          this.showEmailRadio = true;
        }
      } else {
        this.showEmailRadio = false;
      }

      this.showSendPanel = true
      this.hideSignInButton = true
      this.hideSendCodeButton = false
      this.hideVerificationButton = true
      //this.showVerificationPanel = true

    }, err => {


      this.showUserInputError = true;
      this.showSendPanel = false;
      this.showVerificationPanel = false;
    });

  }
  SubmitAuthenticated() {

    if (this.authCode === '') {

    } else {
      const referenceId = this.verificationSentObj.refId;

      this.authService.verificationCode(this.selectedPhone, this.selectedEmail, this.authCode, referenceId,this.authText).subscribe((data) => {
        const responseData = JSON.parse(data);
        if (data) {
          if (responseData.validate) {
            this.authService.isloggedIn = true;
            // Redirected to equipment
            this.router.navigate(['/equipment'], { state: this.customerDetails });
            // this.router.navigate(['/equipment', { id: 'asdfasdfasdfasdf' }]);
          } else {
            this.showverificationInputError = true;
          }
        }
      }, err => {
        this.showverificationInputError = true;
      });
    }
  }

  //if(this.verificationSentObj) {

  // }


}
