<app-header></app-header>
<main role="main" class="main container">

    <div class="content-wrapper">
        <div class="equipment-table">
            <div class="equipment-list-title h5"> Equipment List
                <div class="float-right ">
                    <button type="button" class="btn btn-sm btn-outline-secondary" (click)="getAsserts()" >
                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'><title>ionicons-v5-b</title><path d='M320,146s24.36-12-64-12A160,160,0,1,0,416,294' style='fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px'/><polyline points='256 58 336 138 256 218' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px'/></svg>
                    </button>
                </div>
            </div>

            <div class="equip-table-container">
<div  *ngFor="let item of asserts">
<div class="row content-row" >
    <div class="col-sm first-col">
        <input class="form-check-input equip-checkbox" id="equipOne" (change)="selectEqument(item, $event)" type="checkbox" >
        <label class="form-check-label equip-name" for="equipOne"> {{item.EquipmentModel}}</label></div>

</div>
</div>

<div class="row " [hidden]="!showPickupError">
 <div class="col"  >
<div class="alert alert-danger" role="alert">
    Please select  equipment to proceed
  </div>
</div>
</div>

<div class="row "  [hidden]="(asserts.length > 0)">
    <div class="col" >
   <div class="alert alert-primary" role="alert">
        No equipment found
     </div>
   </div>
   </div>

<div class="row " [hidden]="!(asserts.length > 0)" >
    <div class="col"><button type="button" class="btn btn-outline-dark btn-md pickup-btn" style="min-width: 200px;" (click)="schedulePick()" >Schedule pickup</button></div>

</div>

            </div>

        </div>

        <div class="ero-details">
            <div class="equipment-list-title h5"> Returned Equipment</div>
            <div class="accordion" id="eroDetailsContainer">
                <div class="card" *ngFor="let item of scheduleAsserts" >
                    <div class="card-header" id="headingOne">
                        <span class="mb-0">
                            <strong> ERO number : {{item.OrderID}} </strong></span>
                        <button type="button" style="min-width: 200px;border: 0px;color: black;margin: 0px;background: none;float: right;" (click)="showERODetails(item,item.salesorderid)"  >
                            Show Status <svg class="bi bi-caret-down-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                        </button>

                    </div>

                    <div aria-labelledby="headingOne" *ngIf="item.showDetails"
                        data-parent="#eroDetailsContainer">
                        <div class="card-body">

                            <div class="progress" style="height: 1.5rem;">
                                <div [className]="item.OrderStatus === 'Canceled'? 'progress-bar bg-secondary percent100' : 'progress-bar bg-secondary percent50'">
                                  {{item.OrderStatus}}
                                </div>
                              </div>


                            <div class="mt-2 row">
                                <div class="col-sm-4"><strong>Status:</strong> {{item.OrderStatus}}</div>
                                <div class="col-sm-4"><strong>Created : </strong> {{ item.OrderCreated |  date:'MM/dd/yyyy' }} </div>

                            </div>
                            <div class="mt-2 row">
                                <div class="col-sm-4"><strong>Amount Charged:</strong> {{item.TotalAmount | currency }}</div>
                                <div class="col-sm-4"> </div>

                            </div>
                            <div class="mt-2 row">
                                <div class="col-sm-6" *ngIf="item.equipments" >
                                    <strong>Equipment List:</strong>
                                    <ul>
                                        <li *ngFor="let eq of item.equipments" > {{eq.productid_value_FormattedValue}} </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mt-2 row">
                                <div class="col-sm-4"><strong>Additional Pickup Details:</strong></div>
                                <div class="col-sm-4">
                                    {{item.Instructions}}
                                </div>
                            </div>
                            <div class="mt-2 row">
                                <div class="col-sm-4"><strong>Pickup Date:</strong>{{item.PickupDatetime |  date:'MM/dd/yyyy'}}</div>
                                <div class="col-sm-4"> </div>
                            </div>

                            <div class="mt-2 row" *ngIf="item.OrderStatus === 'Canceled' ? false : true "  >
                                <div class="col-sm-6">
                                   <button type="button" class="btn btn-outline-secondary" (click)="cancelEro(item.OrderID)" >Cancel</button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                         <!--  <ul class="list-group">
                                <li class="list-group-item" *ngFor="let assert of item.asserts" >{{assert.AssertName}}</li>

                            </ul>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
<ng-template #content let-c="close" let-d="dismiss">

    <div class="modal-body">
      loading..
    </div>

  </ng-template>
