<app-header></app-header>
<main role="main" class="main container">
  <div class="content-wrapper">

    <div class="">
      <ol class="breadcrumb" style="background-color: #fff;">
        <li class="breadcrumb-item"><span style="color: #007bff; cursor: pointer;" (click)="RedirectHome()">Home</span>
        </li>
        <li class="breadcrumb-item"><span style="color: #007bff; cursor: pointer;"
            (click)="RedirectSchedule()">Pickup</span></li>
        <li class="breadcrumb-item active" aria-current="page">Review</li>
      </ol>
    </div>

    <div class="accordion scheduling-container" id="reviewNPayContainer">
      <div class="card payment-card">
        <div class="card-header" id="reviewHeader">
          <span class="mb-0">
            <strong> Review</strong></span>
        <!--  <a class=" edit-link " type="button" data-toggle="collapse" data-target="#reviewContainer"
            aria-expanded="true" aria-controls="reviewContainer">
            Edit
          </a>
-->
        </div>
        <div id="reviewContainer" class="collapse show" aria-labelledby="reviewHeader"
          data-parent="#reviewNPayContainer">
          <div class="card-body" id="reviewBody">

            <div class="row">
              <div class="col-md-12">
                <div class="h6 text-strong">Equipment list</div>
              </div>
              <div class="col-md-12 mb-3">
                <ul>
                  <li>
                    <div class="row" *ngFor="let item of pickupdata.assets">
                      <div class="col-md-8">
                        {{item.EquipmentModel}}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="h6 text-strong" style="display:none;" >Shipment Address</div>
                <div class="h6 text-strong" >Pickup Address</div>
                <div class="col-md-12">
                  <div>
                    <span>{{pickupdata.ScheduleAddress.address1}}</span>&nbsp;&nbsp;<span>{{pickupdata.ScheduleAddress.address2}}</span>
                  </div>
                  <div>
                    <span>{{pickupdata.ScheduleAddress.city}}</span>
                  </div>
                  <div>
                    <span>{{pickupdata.ScheduleAddress.state}}</span>&nbsp;-&nbsp;<span>{{pickupdata.ScheduleAddress.zipCode}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
              </div>
            </div>

            <div class="row"  >
              <div class="col-md-6 mb-3">
                <div class="h6 text-strong" >Pickup Time</div>
                <div class="col-md-6">
                  {{ pickupdata.scheduleDate | date:'MM/dd/yyyy' }} {{timeSlot}}
                </div>
              </div>
              <div class="col-md-6 mb-3">
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="h6 text-strong">Payment Amount</div>
                <div class="col-md-6">
                  {{pickupdata.EstimationPrice | currency}}
                </div>
              </div>
              <div class="col-md-6 mb-3">



              </div>
            </div>
            <div  [hidden]="isSubmitted" class="row disc-border"  >
              <h6 class=" col-md-12 mt-2 text-small"><svg width="1em" height="1em" viewBox="0 0 16 16"
                  class="bi bi-exclamation-circle mr-1 mb-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                </svg>Disclaimer</h6>
              <div class="col-md-12 mb-3 alert-light" style="height: 150px; overflow: auto; ">
                <h6 class="text-small">Missed Appointment Charge</h6>
                <p class="text-extra-small">
                  It is your responsibility to be at your home and provide Elite Equipment Return (“EER”) with access to
                  your
                  premises when you schedule a service appointment with EER. EER reserves the right to charge Customer a
                  Missed
                  Appointment charge for missed appointments if you fail to cancel the appointment at least one (1) hour
                  in advance
                  of the scheduled appointment window. The Missed Appointment Charge is currently a one-time charge of
                  $25, but such
                  fee is subject to change in the future upon notice to Customer. This charge applies to all customers.
                </p>

                <h5 class="text-small"> Access to Customer Premises</h5>
                <p class="text-extra-small">
                  Customer grants Elite Equipment Return (“EER”) the right to allow EER, its affiliates or vendors to
                  retrieve
                  equipment in and upon the Customer’s premises. Customer further agrees, upon the termination of
                  service, to
                  provide EER’s representative with access at reasonable times to the premises to remove the equipment
                  supplied by
                  EER’s Clients (it being understood that EER’s representatives’ or clients’ failure to remove its
                  property shall
                  not be deemed an abandonment thereof, nor shall it be deemed a waiver of any of EER’s representatives’
                  or clients’
                  rights or interests related to the equipment). This authorization includes allowing EER or its
                  representatives to
                  be on Customer’s premises outside of Customer’s home, even if Customer is not present. Customer
                  warrants that
                  Customer possesses the authority to grant the rights specified herein and agrees to indemnify and hold
                  EER
                  harmless from any and all claims or damages, including payment of any attorneys’ fees and other legal
                  costs,
                  arising out of the breach of any obligations in this Section.
                </p>
                <h5 class="text-small"> Equipment</h5>
                <p class="text-extra-small">
                  All equipment, including but not limited to cables, wires, amplifiers, cable boxes, modems and remotes
                  delivered
                  to and/or installed in the Customer’s home by Clients of Elite Equipment Return (“EER”) or by
                  representatives of
                  EER’s Clients , remains the property of EER’s Clients; except for wiring installed inside the Premises
                  (“Inside
                  Home Wiring”), or equipment purchased by you from EER’s Clients.Upon EER’s request, Customer will
                  permit EER, and
                  EER’s employees, agents, contractors, and representatives, to access Customer’s premises during
                  reasonable hours
                  to remove the Equipment . This removal will be conducted at an agreed to time, and may result in an
                  additional
                  fee, which will be disclosed to the Customer in writing in advance. Customer agrees that EER is not
                  liable for any
                  NSF, overdraft or other charges that may be imposed upon Customer as a result of charges by EER
                  against Customer’s
                  credit card, security deposit or bank account. EER is not responsible for the maintenance or repair of
                  Customer
                  equipment such as television sets, VCR’s, or other video equipment, stereos or other audio equipment,
                  telephones,
                  A/B switches, Inside Home Wiring or any other Customer equipment or property. EER makes no warranties
                  with respect
                  to the EER’s Clients’ Equipment.
                </p>
              </div>
            </div>

            <div [hidden]="isSubmitted"  class="row">
              <div class="col-md-6 mt-3 mb-3">
                <input type="checkbox" (change)="acceptReviewConditions($event)" class="mr-2" /> <span>Please accept
                  terms and
                  conditions</span>
              </div>

              <div class="col-md-3 mt-2" [hidden]="!showPaymentPanel" >
                <button type="button" class=" btn btn-outline-dark btn-sm btn-block" (click)="submitERO(myPayment)" > Pay
                </button>
              </div>

            </div>
          </div>

        </div>

        <div *ngIf="isSubmittedFail" class="">
          <div class="alert alert-danger" role="alert">
            <p class="mb-0">{{errorMessage}}</p>
          </div>
        </div>



      </div>



    </div>
  </div>
</main>
<app-footer></app-footer>

<ng-template #content let-c="close" let-d="dismiss">

  <div class="modal-body">
    loading..
  </div>

</ng-template>

<ng-template #myPayment let-modal>
  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <iframe [src]="paymentURL" style="width: 1000px; height: 600px;"  >

          </iframe>

        </div>
        <div class="col-12">
          <button class="btn btn-sm btn-primary" (click)="ClosePayment()" >Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
