import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { assetModel, assetScheduleModel, eqtAddress, paymentConformationRequest, NotesRequestModel } from '../model/assertModel';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class assetService {

  constructor(private http: HttpClient) { }


  acceptPayment(status): Observable<any> {
    const Url = environment.assetService + 'Core/acceptPayment/' + status
    return this.http.get<any>(Url);
  }

  getOpenEquipmentsByCustomerID(customerId:string): Observable<any> {
    const Url = environment.assetService + 'Core/getOpenEquipmentsByCustomerID/' + customerId
    return this.http.get<any>(Url);
  }

  getEROsByCustomerId(customerId:string): Observable<any> {
    const Url = environment.assetService + 'Core/ErosByCustomerID/' + customerId
    return this.http.get<any>(Url);
  }

  getSlotsNew(strDate): Observable<any> {
    const Url =  environment.assetService + 'Core/getslots/' + strDate;
    return this.http.get<any>(Url);
  }

  getPickuptypes(clientId:string): Observable<any> {
    const Url = environment.assetService + '/Core/pickuptypes/' + clientId;

    return this.http.get<any>(Url);

  }

  ValidatePickupAddress(postData:any) : Observable<any> {
    const Url = environment.assetService + '/Core/ValidatePickupAddress';

    return this.http.post<any>(Url,postData);

  }

  public cancelERO(eroId: any,orderReason:any,pickupdate:any): Observable<any> {
    let postData = {
      eroId: eroId,
      Comments: orderReason,
      Status: 'Canceled',
      PickupDate:pickupdate
    }
    const url = environment.assetService  + 'Core/eroupdateoca';
    return this.http.post(url,postData);
  }


  public getDetatils(cutomerId): Observable<any> {
    const Url = environment.assetService + 'Core/Details/' + cutomerId;
    return this.http.get<any>(Url);
}
public getSlots(strDate): Observable<any> {
  const Url = environment.assetService + 'Core/getslots/' + strDate;
  return this.http.get<any>(Url);
}

public getERODetatils(eroId): Observable<any> {
  const Url = environment.assetService + 'Core/eroDetails/' + eroId;
  return this.http.get<any>(Url)
}
public updateEROStatus(eroId): Observable<any> {
  const Url = environment.assetService + 'Core/eroupdate/' + eroId;
  return this.http.get<any>(Url)
}

  public getassets(cutomerId): Observable<assetModel[]> {
        const Url = environment.assetService + 'Core/list' + cutomerId;
        return this.http.get<assetModel[]>(Url);
  }

  public getScheduleassets(): Observable<assetScheduleModel[]> {
     const Url = environment.assetService + 'Core/schedulelist';
     return this.http.get<assetScheduleModel[]>(Url);
  }

  public SaveAddress(customerId, address): Observable<any> {
   const Url = environment.assetService + 'Core/addaddress/' + customerId;
   return this.http.post<any>(Url, address);
  }

  public getAddresses(): Observable<eqtAddress[]> {
    const Url = environment.assetService + '/Core/addresseslist';
    return this.http.get<eqtAddress[]>(Url);
  }

  public getEstimations(model: assetScheduleModel): Observable<any> {
    const url = environment.assetService + '/Core/GetEstimation';
    return this.http.post<any>(url, model)
  }
  public getVenderDetatils(customerId,clientId,addressId): Observable<any> {
    const url = environment.assetService + 'Core/getVender/' + clientId + '/' + customerId + '/' + addressId;
    return this.http.get<any>(url);
  }

  public createERO(model: any): Observable<any> {
    const url = environment.assetService + '/Core/CreateERO';
    return this.http.post<any>(url, model);
  }
  public paymentConformation(model: paymentConformationRequest): Observable<any> {
    const url = environment.assetService + 'Core/PaymentConformation';
    return this.http.post<any>(url, model);
  }
  public notes(customerId: string, subject: string, noteObject: any): Observable<any> {
    const request: NotesRequestModel = new NotesRequestModel();
    request.customerId = customerId;
    request.subject = subject;
    request.description = JSON.stringify(noteObject);
    const url = environment.assetService + 'Core/CreateNote';
    return this.http.post<any>(url, request);
  }
}
