<main role="main" class="main container">


   
        <div class="content-wrapper"> 

            <div class="jumbotron jumbotron-fluid">
                <div class="container">
                  <h1 class="display-4">Payment Successful !</h1>              
                    <p class="lead mt-2">Your ERO number is <strong>12345</strong></p>                  
                </div>
              </div>

        </div>

</main>
