import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

   public isloggedIn: boolean;
  public customerId: string;
  public userContext;
  public token = ''
  constructor(private http: HttpClient) { }

public Authenrication(authData: string): Observable<any> {
  const Url = environment.assetService + 'Auth/verify/' + authData;
  return this.http.get<any>(Url)
}
public getIdentity(): Observable<any> {
  const Url = environment.assetService + 'Identity';
  const postdata = {
    clientId: environment.clientId,
    client_Secrite: environment.clientSecrite
}
  return this.http.post<any>(Url,postdata);

}




  public verification(authData: string): Observable<any> {

    const postdata = {
    id:authData

    }

    const Url = environment.assetService + 'Authenctication/verification';
    return this.http.post<any>(Url,postdata)
  }

  public sendVerification(phoneNumber: string,email: string,UserId:string): Observable<any> {

    const postdata = {
    phone:phoneNumber,
    email:email,
    userid:UserId
    }

    const Url = environment.assetService + 'Authenctication/sendVerification';
    return this.http.post<any>(Url,postdata)
  }




  public verificationCode(phone: string,email: string,authCode: string, sid: string,userId: string): Observable<any> {

     const postdata = {
        code:authCode,
        phone:phone,
        email: email,
        verify: sid,
        userid: userId
      }


  const Url = environment.assetService + 'Authenctication/verificationCode';
  return this.http.post<any>(Url,postdata)
}

}
