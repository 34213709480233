<app-header></app-header>
<main role="main" class="main container">
    <div class="equipment-input-container">
        <div class="col-md-12">
            <!-- <img src="../../assets/images/EliteLogosvgBlueSmall1.svg" class="logo-class"> -->
            <!-- <img src="./../../assets/img/EliteLogosvgBlueandBlue.png" class="logo-class"> -->

            <div class="row">
                <div class="col-md-2"></div>
                <form class="mt-5 col-md-7">

                    <div class="input-group mb-3" [hidden]="!hideSignInButton">
                        <input type="text" class="form-control pr-2 mr-2" style="border: 1px solid gray; border-radius: 4px;" name="authText" [(ngModel)]="authText" >
                        <div >
                            <button class="btn btn-sm btn-outline-dark" style="height: 2.5rem;" (click)="Authenticated()">Change</button>
                          </div>
                      </div>

                    <div class="form-group" [hidden]="hideSignInButton">
                        <div style="font-size: 1.35rem; " class="p-2 m-2" >Please enter Mobile Number or Account Number or Email</div>
                        <input type="text" class="form-control resp-text  form-control-lg pl-1" id="userInputKey" name="authText"
                            style="border: 1px solid gray; border-radius: 4px; padding:1px ; height: 2.8rem; "
                            [(ngModel)]="authText" aria-describedby="inputMobile"
                            placeholder="Required" required />
                    </div>
                    <div [hidden]="!showUserInputError" class="alert alert-danger" role="alert">
                       We can not validate your account. Please contact our customer care center.
                    </div>
                    <button type="button" [hidden]="hideSignInButton" class="btn btn-outline-dark btn-lg"
                        (click)="Authenticated()">Sign In</button>
                </form>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <form class="mt-2 col-md-7">
                    <div [hidden]="!showSendPanel">
                        <div class="form-group  alert-primary text-left p-1" [hidden]="!showInformation"  >
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle-fill pr-1 " fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                              </svg>
                            <span class="mb-3 col-md-7 alert-primary rounded-corner"   style="padding-left: 0;" >To Verify your Identity, we need to send you a verification code. <br/> Please select your preferred contact method. <br/>Data charges from your provider may apply.</span>
                        </div>
                        <div class="form-group  alert-danger text-left p-1" [hidden]="!showNoContact">
                            <span class="mb-3 col-md-7 alert-danger rounded-corner"  style="padding-left: 0;" > We can not validate your account. Please contact our customer care center.</span>
                        </div>
                    </div>

                </form>
            </div>
            <form class="mt-2">
                <div class="form-group" [hidden]="!showSendPanel">

                    <div class="row">

                        <div class="col-md-2"></div>
                        <div class=" col-md-3 custom-control custom-radio" [hidden]="!showMobileRadio" >
                            <input type="radio" id="rdphone" name="sendVerification" class="custom-control-input"
                                (change)="selectSource($event)">
                            <label class="custom-control-label" for="rdphone">{{maskedPhone}}</label>
                        </div>


                        <!-- <div class="col-md-3 custom-control custom-radio"  [hidden]="!showEmailRadio"  >
                            <input type="radio" id="rdemail" name="sendVerification" class="custom-control-input"
                                (change)="selectSource($event)">
                            <label class="custom-control-label" for="rdemail">{{maskedEmail}}</label>
                        </div> -->
                    </div>
                    <div [hidden]="!showsendInputError" class="alert alert-danger" role="alert">
                        Unable to send
                    </div>
                    <!--
<button type="button" [hidden]="hideSendCodeButton"  class="btn btn-outline-dark btn-lg mt-4" (click)="sendVerification()">Get Code</button> -->

                </div>






            </form>
            <div class="row" [hidden]="!showVerificationPanel">
                <div class="col-md-2"></div>
                <form class="mt-2 col-md-7">
                    <div class="form-group">
                        <label for="userInputKey" id="inputMobile" hidden>Enter Authentication Code</label>
                        <div class="row">

                        </div>
                        <!--  <input type="text" class="form-control  form-control-lg" id="userInputKey" name="authCode"
                            [value]="authCode" [(ngModel)]="authCode" aria-describedby="inputMobile"
                            placeholder="Enter your verification code" required> -->
                        <div class="input-group mb-3"
                            style="border: 1px solid gray; border-radius: 4px; padding:1px ; height: 2.8rem; ">
                            <input type="text" style="border: 0;" class="form-control" [disabled]="verification_code_disabled" [value]="authCode" [(ngModel)]="authCode"  name="authCode"
                                placeholder="Verification code" aria-label="Recipient's username"
                                aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button [hidden]="timeLeft > 0"  class="btn get-code-btn" (click)="sendVerification()" type="button">Get Code</button>
                                <span class="btn get-code-btn" [hidden]="timeLeft === 0" >00:{{timeLeft}}</span>
                            </div>
                        </div>

                    </div>

                    <div [hidden]="!showverificationInputError" class="alert alert-danger" role="alert">
                        not a valid code
                    </div>

                    <button type="button" class="btn btn-outline-dark btn-lg" (click)="SubmitAuthenticated()">Sign
                        in</button>
                </form>
            </div>
        </div>
    </div>


</main><!-- /.container -->
<app-footer></app-footer>
